import React from 'react';

class About extends React.Component {
  render() {
    return (
      <h2>About</h2>
    );
  }
}

export default About;

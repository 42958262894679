import React from 'react';

import { Pagination as BsPagination} from "react-bootstrap";

class Pagination extends React.Component {

  render() {
    let pages = [];
    for (let cnt = Math.max(this.props.currentPage - 2, 1); cnt <= Math.min(this.props.currentPage + 2, this.props.pageCount); cnt++) {
      pages.push(<BsPagination.Item key={cnt} active={this.props.currentPage === cnt} onClick={() => this.props.onClick(cnt)}>{cnt}</BsPagination.Item>);
    }
    return (
      <BsPagination>
        <BsPagination.First disabled={this.props.currentPage === 1} onClick={() => this.props.onClick(1)} />
        <BsPagination.Prev disabled={this.props.currentPage === 1} onClick={() => this.props.onClick(this.props.currentPage - 1)} />
        {pages}
        <BsPagination.Next disabled={this.props.currentPage === this.props.pageCount} onClick={() => this.props.onClick(this.props.currentPage + 1)} />
        <BsPagination.Last disabled={this.props.currentPage === this.props.pageCount} onClick={() => this.props.onClick(this.props.pageCount)} />
      </BsPagination>
    )
  }
}

export default Pagination;

import React from 'react';

class Home extends React.Component {
  render() {
    return (
      <h2>Home</h2>
    );
  }
}

export default Home;

import { authenticationService } from '../services/authentication.service';

export function authHeader() {
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.data.token) {
    return { Authorization: `Bearer ${currentUser.data.token}`, 'Content-Type': 'application/json', 'Accept': 'application/json' };
  } else {
    return {};
  }
}

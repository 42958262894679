import React from 'react';

class SortHeader extends React.Component {

  render() {
    let direction = '';
    if (this.props.currentSort === this.props.name) {
      direction = this.props.currentDirection === 'asc' ? 'sort-up' : 'sort-dn';
    }
    return (
      <th><a href="/#" className={"text-decoration-none " + direction} onClick={(e) => {e.preventDefault(); this.props.onClick(this.props.name)}}>{this.props.children}</a></th>
    )
  }
}

export default SortHeader;

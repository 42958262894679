import React from 'react';
import { Button, Col, Container, Modal, Nav, Navbar, Row, Table} from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';

import { authenticationService } from '../services/authentication.service';
import { hostService } from '../services/host.service';
import Pagination from './Pagination';
import SortHeader from './SortHeader';

class Hosts extends React.Component {
  constructor(props) {
    super(props);

    Moment.updateLocale('en', {
      invalidDate: ''
    })
    this.state = {
      formMode: null,
      showDelete: false,
      currentUser: authenticationService.currentUserValue,
      currentHost: null,
      currentPage: 1,
      currentSort: 'name',
      currentDirection: 'asc',
      hosts: null,
      pagination: null
    };
  }

  componentDidMount() {
    this.updateList({page: 1});
  }

  updateList(changes) {
    let page = this.state.currentPage;
    let sort = this.state.currentSort;
    let direction = this.state.currentDirection;
    if (changes.hasOwnProperty('page')) {
      page = changes.page;
      this.setState({formMode: null, currentPage: page});
    }
    if (changes.hasOwnProperty('sort')) {
      let newSort = changes.sort;
      if (newSort === sort) {
        direction = (direction === 'asc') ? 'desc' : 'asc';
      } else {
        sort = newSort;
        direction = 'asc';
      }
      this.setState({formMode: null, currentSort: sort, currentDirection: direction});
    }
    if (changes.hasOwnProperty('direction')) {
      direction = changes.direction;
      this.setState({formMode: null, currentDirection: direction});
    }
    hostService.getAll(page, sort, direction).then(data => {
      this.setState({ hosts: data.hosts, pagination: data.params });
    });
  }

  showAddForm(isShow) {
    this.setState({formMode: isShow ? 'add' : null, currentHost: {name: '', code: 'xxx'}});
  }

  showEditForm(hostIndex) {
    const host = this.state.hosts[hostIndex];
    this.setState({formMode: 'edit', currentHost: host});
  }

  showDelete(hostIndex) {
    const host = this.state.hosts[hostIndex];
    this.setState({showDelete: true, currentHost: host});
  }

  hideDelete(isDelete) {
    if (isDelete) {
      hostService.deleteHost(this.state.currentHost)
        .then(
          data => {
            if (data.status) {
              this.updateList();
            } else {
              console.log(data.message);
            }
          },
          error => {
            console.log(error);
          }
        );
    }
    this.setState({showDelete: false});
  }

  render() {
    const hosts = this.state.hosts;
    return (
      <>
        <Navbar bg="light">
          <Navbar.Brand>Hosts</Navbar.Brand>
          <Navbar.Collapse id="page-header">
            <Nav className="mr-auto">
            </Nav>
            <Nav className="justify-content-end">
              <Nav.Link onClick={this.showAddForm.bind(this, true)}>Add</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Modal show={this.state.showDelete} onHide={this.hideDelete.bind(this, false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please confirm delete host {this.state.currentHost ? this.state.currentHost.name : ''}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.hideDelete.bind(this, false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.hideDelete.bind(this, true)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.formMode &&
          <Container>
            <Row>
              <Col md={{span: 4, offset: 4}}>
                <Formik
                  initialValues={this.state.currentHost}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required('Name is required'),
                    code: Yup.string().required('Code is required')
                  })}
                  onSubmit={(formValue, { setStatus, setSubmitting }) => {
                    setStatus();
                    hostService.saveHost(formValue)
                      .then(
                        data => {
                          setSubmitting(false);
                          if (data.status) {
                            this.updateList({page: 1});
                          } else {
                            setStatus(data.message);
                          }
                        },
                        error => {
                          setSubmitting(false);
                          setStatus(error);
                        }
                      );
                  }}
                  render={({ errors, status, touched, isSubmitting }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <Field autoFocus name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                      </div>
                      {this.state.formMode === 'edit' &&
                        <>
                          <div className="form-group">
                            <label htmlFor="code">Code</label>
                            <Field name="code" type="text" className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')} />
                            <ErrorMessage name="code" component="div" className="invalid-feedback" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="current_address">IP Address</label>
                            <Field name="current_address" type="text" className={'form-control' + (errors.current_address && touched.current_address ? ' is-invalid' : '')} />
                            <ErrorMessage name="current_address" component="div" className="invalid-feedback" />
                          </div>
                        </>
                    }
                      <div className="form-group">
                        <Button variant="primary" type="submit" disabled={isSubmitting}>OK</Button>
                        {isSubmitting &&
                          <img alt="Loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                        &nbsp;
                        <Button variant="light" type="reset" onClick={this.showAddForm.bind(this, false)} className="btn btn-default" disabled={isSubmitting}>Cancel</Button>
                      </div>
                      {status &&
                        <div className={'alert alert-danger'}>{status}</div>
                      }
                    </Form>
                  )}
                />
              </Col>
            </Row>
          </Container>
        }
        {hosts &&
          <>
            <Table>
              <thead>
                <tr>
                  <SortHeader name="name" currentSort={this.state.currentSort} currentDirection={this.state.currentDirection} onClick={(i) => this.updateList({sort: i})}>Name</SortHeader>
                  <SortHeader name="code" currentSort={this.state.currentSort} currentDirection={this.state.currentDirection} onClick={(i) => this.updateList({sort: i})}>Code</SortHeader>
                  <SortHeader name="current_address" currentSort={this.state.currentSort} currentDirection={this.state.currentDirection} onClick={(i) => this.updateList({sort: i})}>Current Address</SortHeader>
                  <SortHeader name="last_change" currentSort={this.state.currentSort} currentDirection={this.state.currentDirection} onClick={(i) => this.updateList({sort: i})}>Last Change</SortHeader>
                  <SortHeader name="last_update" currentSort={this.state.currentSort} currentDirection={this.state.currentDirection} onClick={(i) => this.updateList({sort: i})}>Last Update</SortHeader>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {hosts.map((host, index) =>
                  {
                    return (
                      <tr key={host.id}>
                        <td>{host.name}</td>
                        <td className="text-monospace">{host.code}</td>
                        <td>{host.current_address}</td>
                        <td>{Moment(host.last_change).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td>{Moment(host.last_update).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td>
                          <Button size="sm" onClick={this.showEditForm.bind(this, index)}><i className="far fa-edit"></i></Button>&nbsp;
                          <Button size="sm" onClick={this.showDelete.bind(this, index)}><i className="far fa-trash-alt"></i></Button>
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </Table>
            <Pagination currentPage={this.state.currentPage} pageCount={this.state.pagination.pageCount} onClick={(i) => this.updateList({page: i})} />
          </>
        }
      </>
    );
  }
}

export default Hosts;

import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { handleResponse } from '../helpers/handle-response';

export const hostService = {
  getAll,
  saveHost,
  deleteHost
};

function getAll(page, sort, direction) {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`${config.apiUrl}/api/list?page=${page}&sort=${sort}&direction=${direction}`, requestOptions).then(handleResponse);
}

function saveHost(host) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(host)
  };
  if (host.id) {
    return fetch(`${config.apiUrl}/api/edit/${host.id}`, requestOptions).then(handleResponse);
  } else {
    return fetch(`${config.apiUrl}/api/add`, requestOptions).then(handleResponse);
  }
}

function deleteHost(host) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/api/delete/${host.id}`, requestOptions).then(handleResponse);
}

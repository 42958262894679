import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { authenticationService } from './services/authentication.service';

import About from './components/About';
import Home from './components/Home';
import Hosts from './components/Hosts';
import Login from './components/Login';
import { PrivateRoute } from './components/PrivateRoute';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(currentUser => this.setState({ currentUser: currentUser }));
  }

  logout() {
    authenticationService.logout();
  }

  render() {
    let currentUser = this.state.currentUser;
    return (
      <div className="App">
      <Router basename="/admin">
        <div>
          <Navbar>
            <LinkContainer to="/"><Navbar.Brand>DDNS Master</Navbar.Brand></LinkContainer>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <LinkContainer to="/about"><Nav.Link>Abouts</Nav.Link></LinkContainer>
                <LinkContainer to="/hosts"><Nav.Link>Hosts</Nav.Link></LinkContainer>
                <NavDropdown title="Reports" id="basic-nav-dropdown">
                  <LinkContainer to="/reports/1"><NavDropdown.Item>Report 1</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/reports/2"><NavDropdown.Item>Report 2</NavDropdown.Item></LinkContainer>
                </NavDropdown>
              </Nav>
              <Nav className="justify-content-end">
                { currentUser ?
                  <>
                    <Navbar.Text>&nbsp;{currentUser.user.username}&nbsp;</Navbar.Text>
                    <Nav.Link onClick={this.logout}>Logout</Nav.Link>
                  </>
                  :
                  <LinkContainer to="/login"><Nav.Link>Login</Nav.Link></LinkContainer>
                }
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Switch>
            <PrivateRoute path="/hosts" component={Hosts} />
            <Route path="/login" component={Login} />
            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
    );
  }
}

export default App;
